import React from 'react';
import './navbar.css';

const NavBar = () => {
  return (
    <nav className='navbar'>
      <ul>
        <li><a href="/" className='logo-link'><span className='logo'>Shorts Maker</span></a></li>
        <li className='center-link'><a href="/most-replayed-moments" className='logo-link'><span className='logo'>Most Replayed Moments</span></a></li>
        <li style={{ float: 'right' }}><a href="/about" className='logo-link'><span className='logo'>Информация по проекту</span></a></li>

      </ul>
    </nav>
  )
}

export default NavBar